import eventsHandler from '@/services/common/eventsHandler.js'

class serviceForm extends eventsHandler {
  constructor() {
    super()
    this._service = {}
    this._valid = false
  }

  get service() {
    const service = {
      ...this._service
    }
    return service
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._service.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetService() {
    this._service = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {service: {}})
  }

  updateField(key, value) {
    this._service[key] = value
    this._executeCallbacksOf('update', {service: this._service})
    this._executeCallbacksOf('update-' + key, {key: this._service[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(service) {
    this._service = service
    this._executeCallbacksOf('update', {service: this._service})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new serviceForm()