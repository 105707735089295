<template>
  <div>
    <v-list flat dense>
      <draggable v-model="phases" @start="startTouch" @end="endTouch" handle=".draggable-area" ghost-class="ghost" ref="draggableComponent">
        <div v-for="(phase) in phases" v-bind:key="phase.id">
          <v-list-item>
            <v-list-item-avatar>
              <v-icon small class="draggable-area mx-2 mr-4">mdi-menu</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <div>
                <v-container v-if="!editing || editPhase.id != phase.id || (editPhase.id == undefined && editPhase.tempId != phase.tempId)" fluid class="pa-0">
                  <v-row class="ma-0 pa-0">
                    <v-col class="pa-0">
                      {{phase.name}}
                    </v-col>
                    <v-col class="pa-0">
                      <template v-if="phase.minutes != undefined && phase.minutes != ''">
                        <v-icon small class="mr-1">mdi-clock</v-icon>
                        {{humanReadableMinutes(phase.minutes)}}
                      </template>
                    </v-col>
                  </v-row>
                </v-container>
                <v-container v-else fluid class="pa-0">
                  <v-row class="ma-0 pa-0">
                    <v-col class="pa-0">
                      <v-text-field v-model="editPhase.name" hide-details="auto" class="ma-0" filled dense></v-text-field>
                    </v-col>
                    <v-col class="pa-0 ml-1">
                      <DurationPicker v-model="editPhase.minutes"></DurationPicker>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-list-item-content>
            <v-list-item-action>
              <div v-if="!editing || editPhase.id != phase.id || (editPhase.id == undefined && editPhase.tempId != phase.tempId)" class="d-flex">
                <v-btn icon @click="edit(phase)">
                  <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon @click="remove(phase)">
                  <v-icon small>mdi-trash-can-outline</v-icon>
                </v-btn>
              </div>
              <v-btn icon v-else @click="submitEdit">
                <v-icon small>mdi-check</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
        </div>
      </draggable>
    </v-list>
    <div class="d-flex justify-center">
      <StandardDialog 
        title="Nuova fase"
        v-model="newPhaseDialog"
        :dialog-height="null"
        dialog-max-width="500px"
      >
        <template v-slot:activator="{on, attrs}">
          <v-btn icon v-on="on" v-bind="attrs">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-form v-model="createValid" class="mt-2" ref="newForm">
          <v-text-field
            filled
            label="Nome"
            v-model="createPhase.name"
            :rules="[presenceRule]"
          ></v-text-field>
          <DurationPicker v-model="createPhase.minutes"></DurationPicker>
        </v-form>
        <template v-slot:footer>
          <v-spacer></v-spacer>
          <v-btn text color="error" @click="newPhaseDialog = false">Annulla</v-btn>
          <v-btn text color="default" @click="submitCreate" :disabled="!createValid">Aggiungi</v-btn>
        </template>
      </StandardDialog>
    </div>

  </div>
</template>

<script>
import draggable from 'vuedraggable'
import DurationPicker from '@/components/common/DurationPicker.vue'
import StandardDialog from '@/components/common/StandardDialog.vue'
import dateUtils from '@/mixins/common/dateUtils.js'

export default {
  name: "PhasesForm",
  mixins: [dateUtils],
  components: {
    draggable,
    DurationPicker,
    StandardDialog
  },
  data: function() {
    return {
      phases: [...this.value],
      drag: false,
      editPhase: undefined,
      createPhase: {},
      newPhaseDialog: false,
      editValid: false,
      createValid: false,
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
    }
  },
  mounted: function() {
  },
  props: {
    value: {
      type: Array,
      default: () => ([])
    }
  },
  methods: {
    edit(phase) {
      this.editPhase = {...phase}
    },
    remove(phase) {
      let confirmed = confirm('Sei sicuro di voler rimuovere il servizio?')

      if(confirmed) {
        this.phases = this.phases.filter((el) => { return el.id != phase.id})
        this.$emit('remove-phase', phase)
        this.$emit('input', this.phases.map((el) => { 
          delete el.tempId
          return { ...el } 
        }))
      }
    },
    submitCreate() {
      if(!this.createPhase.minutes){
        alert('Inserire una durata')
      } else {
        let id = this.getUniqId()
        this.phases.push({tempId: id, ...this.createPhase})
        
        this.$emit('create-phase', this.createPhase)
        this.newPhaseDialog = false
        this.createPhase = {}
        this.$refs.newForm.resetValidation()
        this.$emit('input', this.phases.map((el) => { 
          delete el.tempId
          return { ...el } 
        }))
      }
    },
    submitEdit() {
      let index;
      for(var i = 0; i < this.phases.length; i++) {
        if(this.editPhase.id != undefined && this.phases[i].id == this.editPhase.id) {
          index = i
        } else if (this.editPhase.id == undefined && this.phases[i].tempId == this.editPhase.tempId) {
          index = i
        }
      }
      if(index != undefined) {
        this.phases[index] = Object.assign({}, this.phases[index], this.editPhase)
        this.$emit('update-phase', this.editPhase)
        this.$emit('input', this.phases.map((el) => { 
          delete el.tempId
          return { ...el } 
        }))
      }
      this.editPhase = undefined
    },
    getUniqId() {
      let max = 0;
      for(let i = 0; i < this.phases.length; i++) {
        if(max == undefined || parseInt(this.phases[i].tempId) > max) {
          max = parseInt(this.phases[i].tempId)
        }
      }
      return max + 1
    },
    startTouch() {
      this.drag = true
    },
    endTouch(context) {
      this.drag = false
      this.$emit('input', this.phases.map((el) => { 
        delete el.tempId
        return { ...el } 
      }))
      this.$emit('move-phase', this.phases[context.newIndex], context.newIndex)
    }
  },
  computed: {
    editing() {
      return !!this.editPhase
    }
  },
  watch: {
    value(newValue) {
      this.phases = newValue
    }
  }
}
</script>

<style>

.draggable-area {
	cursor: move
}

.ghost {
	background-color: #b6b6b6;
	opacity: 0.2;
}

</style>